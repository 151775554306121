:root {
    --white: #ffffff;
    --grey: #c3c3d9;
    --grey2: #b5b5b5;
    --grey3: #e5e5e5;
    --black: #17161F;
    --blackInput: #24242A;
    --blackPay: #272631;
    --purple: #E37CFF;
    --blue: #298BA3;
    --blueHover: #31A6C4;
    --blueHoverLink: #1BC9F9;
    --red: #D03235;
    --red20: #FF6568;
    --redBlack: #5c2f2f;
    --green: #35d298;
    --green20: #68FFCB;
    --greenBlack: #3b5c2f;
    --yellow: #d2b035;
    --yellow20: #FFE368;
    --yellowBlack: #5c552f;
    --logoBlue: #01AFDF;
    --logoGreen: #15F4BC;
    --hover-brightness: 1.2;
    --hover-darkness: 0.8;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    /* font-family: 'Open Sans', sans-serif; */
}

a {
    text-decoration: none;
    font-size: 16px;
    color: white;
}

.navbar .button:hover {
    color: var(--white);
    background-color: var(--blueHover);
    filter: none;
}

.navbar a:hover {
    filter: brightness(var(--hover-darkness));
}

.footer a:hover {
    filter: brightness(var(--hover-darkness));
}

.link {
    text-decoration: none;
    font-size: 16px;
    color: var(--logoBlue);
    stroke: var(--logoBlue);
}

.link:hover {
    color: var(--blueHoverLink);
    stroke: var(--blueHoverLink);
}

.animatedProgress {
    width: 150px;
    height: 20px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: var(--blackInput);
}

.animatedProgressInternal {
    height: 100%;
    width: 0;
    position: absolute;
    background: linear-gradient(90deg, var(--logoBlue) 50%, var(--logoGreen));
}


.animatedProgressText {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    place-items: center;
    justify-content: center;
    color: var(--grey3);
    text-shadow:
    -1px -1px 0 var(--blackInput),  
     1px -1px 0 var(--blackInput),
     -1px 1px 0 var(--blackInput),
      1px 1px 0 var(--blackInput);
}
  

ul {
    list-style-type: none;
}

.text {
    width: 100%;
    border-radius: 2px;
    border: none;
    color: white;
    padding: 0.5em 1em;
    background: var(--blackInput);
    min-width: 280px;
    height: 42px;
}

input[aria-invalid='true'] {
    border: 2px solid var(--red);
}

div[aria-invalid='true'] {
    border: 2px solid var(--red);
}

textarea[aria-invalid='true'] {
    border: 2px solid var(--red);
}

.isInvalid {
    border: 2px solid var(--red);
}

.text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey2);
    opacity: 1;
    /* Firefox */
}

.text:focus {
    outline-style: none;
    -webkit-box-shadow: inset 0px 0px 0px 1px var(--grey2);
    ;
    -moz-box-shadow: inset 0px 0px 0px 1px var(--grey2);
    ;
    box-shadow: inset 0px 0px 0px 1px var(--grey2);
    ;
}

.textSearch {
    border-radius: 2px;
    border: none;
    color: white;
    padding: 0.5em;
    background: var(--black);
    min-width: 280px;
    height: 42px;
    padding-left: 40px;
}

.textSearch::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey2);
    opacity: 1;
    /* Firefox */
}

.textSearch:focus {
    outline-style: none;
    -webkit-box-shadow: inset 0px 0px 0px 1px var(--grey2);
    ;
    -moz-box-shadow: inset 0px 0px 0px 1px var(--grey2);
    ;
    box-shadow: inset 0px 0px 0px 1px var(--grey2);
    ;
}

.select-search__input {
    color: white;
    padding: 0.5em;
    background: var(--blackInput) !important;
    padding-left: 40px !important;
    height: 42px !important;
}

.select-search__input::placeholder {
    color: var(--grey2);
    opacity: 1;
}

.searchButton {
    stroke: white;
    position: absolute;
    top: 10px;
    left: 10px;
}

.coinSeachResult {
    background: none;
    border: none;
    width: 100%;
    padding: 5px;
    text-align: start;
    cursor: pointer;
}

.coinSeachResultAdd {
    text-align: center;
    height: 70px;
}


.coinSeachResultNoting {
    text-align: center;
    height: 30px;
}

.textarea {
    resize: none;
    overflow-x: hidden;
    height: 100px;
    display: block;
}

input[type="date"]:before {
    color: white;
    content: attr(placeholder);
    margin-right: 0.5em;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

body {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    background-image: url('./back3.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #040305;
    max-width: 1600px;
    margin: auto;
    min-height: 100vh;
}

.App {
    padding: 0 30px;
}

.add2row {
    display: flex;
    justify-content: space-between;
}

.add2row>.text {
    width: 280px;
}

.addcol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-wrap {
    padding-bottom: 100px;
    /* Footer height */
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    padding-top: 35px;
    font-size: 16px;
    align-items: center;
}

.navbar {
    display: flex;
    align-items: center;
}

.navbar-margin {
    margin-right: 30px;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    font-size: 16px;
    align-items: center;
    width: 100%;
}

.footer-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.footer-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    gap: 20px;
}

.footer-cop {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 50px;
    text-align: center;
    color: var(--grey)
}

.hugeTitle {
    font-size: 60px;
    font-weight: bold;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
}

.terms {
    max-width: 260px;
    margin: 24px auto 0px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: var(--grey);
}

.termsPay {
    max-width: 260px;
    margin: 10px auto 0px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: var(--grey);
}

.termsLink {
    color: var(--blue);
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}

.termsLink:hover {
    color: rgb(21, 50, 155);
}

.button {
    background-color: var(--blue);
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: var(--white);
    padding: 12px 30px;
    text-decoration: none;
    border: none;
    min-width: 130px;
    text-align: center;
}

.button:disabled {
    background-color: var(--grey2);
    cursor: default;
}

.button:hover:not([disabled]) {
    color: var(--white);
    background-color: var(--blueHover);
    filter: none;
}

.buttonVoteBig {
    font-size: 18px !important;
}

.buttonVote {
    background-color: transparent;
    border: 1px solid var(--blue);
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: var(--white);
    padding: 12px 15px;
    text-decoration: none;
    /* border: none; */
    min-width: 130px;
    text-align: center;
    transition: .2s all linear;
}

.buttonVote:hover {
    background-color: var(--blue);
    color: var(--white);
}

.buttonFilter {
    background-color: var(--black);
    border: 1px solid var(--blue);
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: var(--white);
    padding: 12px 30px;
    text-decoration: none;
    /* border: none; */
    min-width: 150px;
    text-align: center;
    transition: .2s all linear;
}

.buttonFilter:hover {
    background-color: var(--blue);
    color: var(--white);
}

.buttonVoteSelect {
    background-color: var(--blue);
    color: var(--white);
}

.buttonStandard {
    background-color: transparent;
    border: 1px solid var(--blue);
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: var(--white);
    padding: 12px 30px;
    text-decoration: none;
    /* border: none; */
    min-width: 130px;
    text-align: center;
    transition: .2s all linear;
    font-size: 14px;
}

.buttonStandard:hover {
    background-color: var(--blue);
    color: var(--white);
}

.buttonIcon {
    width: 24px;
    height: 24px;
    stroke: var(--grey2);
}

.buttonIcon:hover {
    stroke: var(--white);
}

.listForm {
    background: var(--black);
    padding: 30px;
    border-radius: 5px;
    margin: auto;
    max-width: 100%;
}

.listFormAddCoin {
    background: var(--black);
    padding: 30px;
    border-radius: 5px;
    margin: auto;
    max-width: 640px;
}

.popup {
    width: 100%;
    max-width: 450px;
    background-color: var(--black);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

.cartWrapper {
    background: var(--black);
    padding: 30px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
}

.logo {
    height: 40px;
    width: 260px;
}

.logo:hover {
    filter: drop-shadow(0 0 1rem var(--logoGreen))
}

.title {
    margin-bottom: 35px;
}

.tableHeader {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.tableSelectableButton {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.tableImg {
    border-radius: 50%;
    margin-right: 5px;
}

.tableImgStructure {
    display: flex;
    align-items: center;
    min-width: 180px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
}

.rdt_Table {
    padding: 0px 10px;
}

.rdt_TableHeader {
    justify-content: space-between;
}

.tableName {
    color: var(--grey3);
    max-width: 180px;
    font-weight: bold;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.tableSymb {
    color: var(--grey2);
    filter: brightness(var(--hover-darkness));
    text-transform: uppercase;
    font-size: 12px;
    white-space: nowrap;
    display: block;
    font-weight: bold;
}

.mobile-option {
    display: none;
}

.mobile-menu {
    display: none;
}

.uploadPlaceholder {
    min-width: 280px;
    width: 100%;
    height: 170px;
    background-color: var(--blackInput);
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 2px;
}

.uploadPlaceholderDrag {
    width: 280px;
    height: 170px;
    background-color: var(--blue);
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 2px;
}

.coinInfoWrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
}

.coinInfoStructure {
    min-width: 450px;
    width: 100%;
}

.coinInfoRow {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    color: var(--grey2);
}

.scannerForm {
    display: flex;
    align-items: center;
    place-content: center;
    gap: 20px;
    margin-bottom: 50px;
}

.scannerFormSelect {
    width: 200px;
}

.scannerTitle {
    display: flex;
    gap: 10px;
}

.scannerSafetyIndex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.scannerInfoRow {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    align-items: center;
}

.scanerInfoRowData {
    font-size: 14px;
    color: var(--grey2);
}

.scannerInfoWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 450px;
}

.scannerSecurityInfoWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}


.coinInfoWrapperInfo {
    width: 100%;
    min-width: 250px;
    max-width: 400px;
}

.coinInfoCoinBrainWrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 50px;
}

.coinInfoRowData {
    font-size: 16px;
    color: var(--white);
}

.watchDescription {
    margin-top: 10px;
    font-size: 16px;
    min-height: 150px;
    word-break: break-word;
    color: var(--white);
}

.titleCarousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.carousel.carousel-slider {
    border-radius: 5px;
    max-width: 584px;
}

.carousel.carousel-slider:hover {
    filter: drop-shadow(0 0 0.5rem var(--logoGreen)) drop-shadow(0 0 1rem var(--logoBlue));
    /* box-shadow: 0 0 15px 3px var(--logoGreen), 0 0 30px 6px var(--logoBlue) */
}

.carousel.carousel-slider::after {
    content: 'AD';
    background-color: var(--blue);
    color: var(--white);
    padding: 5px;
    border-radius: 5px;
    font-size: 8px;
    position: absolute;
    bottom: 3px;
    left: 3px;
}

.policy {
    max-width: 1000px;
    padding: 0 10px;
    margin: auto;
    color: var(--grey);
}

.policy h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    color: var(--white);
}

.policy h1 {
    margin-bottom: 10px;
    color: var(--white);
}

.policy ul {
    list-style-type: disc;
}

.policy li {
    margin-bottom: 5px;
}

.policy p {
    margin-bottom: 10px;
}

.advStructure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    align-items: flex-start;
    max-width: 1000px;
    margin: auto;
}

.react-tabs__tab-panel:not(.react-tabs__tab-panel--selected) {
    display: none;
}

.react-tabs__tab-list {
    display: flex;
    justify-content: space-between;
}

.react-tabs__tab[aria-invalid='true'] {
    border: 1px solid var(--red);
}

.react-tabs__tab:hover[aria-invalid='true'] {
    background-color: var(--red) !important;
}

.react-tabs__tab--selected[aria-invalid='true'] {
    background-color: var(--red) !important;
}

.react-tabs__tab {
    background-color: var(--black);
    border: 1px solid var(--blue);
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: var(--white);
    padding: 12px 30px;
    text-decoration: none;
    /* border: none; */
    min-width: 130px;
    width: 200px;
    text-align: center;
    transition: .2s all linear;
}

.react-tabs__tab:hover {
    background-color: var(--blue);
}

.react-tabs__tab--selected {
    background-color: var(--blue);
}

.rmdp-calendar {
    width: 100%;
}

.rmdp-header {
    font-size: 18px;
    width: 100%;
    text-transform: uppercase;
}

.rmdp-week {
    padding: 5px;
}

.rmdp-day {
    height: 34px !important;
    width: 34px !important;
}

.rmdp-day-picker>div {
    width: 100%;
}

.rmdp-week-day {
    font-size: 18px;
    color: var(--blue) !important;
    text-transform: uppercase;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--blue) !important;
    box-shadow: none !important;
    color: #fff !important;
}

.rmdp-day span {
    border-radius: 50%;
    bottom: 0;
    font-size: 18px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px;
}

.rmdp-arrow {
    height: 8px !important;
    width: 8px !important;
}

.rmdp-wrapper {
    background-color: var(--blackInput) !important;
    box-shadow: none !important;
    width: 100% !important;
    max-width: 450px;
}

.rmdp-day.rmdp-today span {
    background-color: transparent !important;
    color: #fff !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: var(--blue) !important;
    color: #fff !important;
}

.rmdp-disabled {
    color: #87898b !important;
}

.bg-dark .rmdp-day:not(.rmdp-deactive) {
    color: #fff;
}

.name {
    color: #d9d9d9;
    max-width: 300px;
    font-weight: bold;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.nameCard {
    color: #d9d9d9;
    max-width: 210px;
    font-weight: bold;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.sym {
    color: #6e767d;
    text-transform: uppercase;
    font-size: 18px;
    white-space: nowrap;
    display: block;
}

.google-btn {
    width: 240px;
    height: 50px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 25%);
    display: flex;
}

.google-icon-wrapper {
    padding: 15px;
    background-color: #fff;
    display: flex;
    justify-content: center;
}

.google-icon {
    width: 18px;
    height: 18px;
}

.btn-text {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.2px;
    align-self: center;
    text-align: center;
    width: 100%;
    font-weight: 500;
}

.google-btn:hover {
    box-shadow: 0 0 6px #4285f4;
}

.google-btn:active {
    background: #1669F2;
}

.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
    border-radius: 3px;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.selectResultWrapper {
    background-color: var(--blackInput);
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.iconVote {
    width: 22px;
    height: 22px;
}

.topCardUrl:hover {
    background-color: var(--blackPay);
}

.topCardUrl {
    background: var(--black);
    padding: 30px;
    border-radius: 5px;
    max-width: 350px;
    width: 100%;
}

.topa {
    position: sticky;
    bottom: 0;
}

.errorWrap {
    animation: neonRed 3s ease-in-out infinite alternate;
    margin-top: 110px;
    border-radius: 5px;
    height: fit-content;
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: var(--black);
    padding: 30px;
}

.messageWrap {
    animation: neonBlue 3s ease-in-out infinite alternate;
}

.portfolioVisibility {
    display: flex;
    gap: 10px;
    margin-right: -34px;
    align-items: center;
}

.scannerError {
    display: flex;
    align-items: center;
    place-content: center;
    gap: 10px;
    margin-bottom: 50px;
    margin-top: 100px; 
}

.form_group_recaptcha> div > div {
    display: flex;
    place-content: center;
}

.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: var(--white);
    border-radius: 18px;
    border: 1px solid var(--grey);
  }

  .close:focus {
    outline: none !important; 
    box-shadow: none !important;
  }

@keyframes neonBlue {
    0% {
        filter: drop-shadow(0 0 0.5rem var(--logoGreen)) drop-shadow(0 0 1rem var(--logoBlue));
    }
    50% {
        filter: drop-shadow(0 0 1rem var(--logoGreen)) drop-shadow(0 0 2rem var(--logoBlue));
    }
    100% {
        filter: drop-shadow(0 0 0.5rem var(--logoGreen)) drop-shadow(0 0 1rem var(--logoBlue));
    }
}


@keyframes neonRed {
    0% {
        filter: drop-shadow(0 0 60px var(--red));
    }
    50% {
        filter: drop-shadow(0 0 20px var(--red));
    }
    100% {
        filter: drop-shadow(0 0 60px var(--red));
    }
}

@keyframes breathing {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    60% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
    25% {
        transform: perspective(120px) rotateX(0deg) rotateY(180deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(180deg);
    }
    50% {
        transform: perspective(120px) rotateX(0deg) rotateY(270deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(270deg);
    }
    100% {
        transform: perspective(120px) rotateX(0deg) rotateY(360deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(360deg);
    }
}

@media (max-width: 850px) {
    .logo {
        height: 30px;
        width: 230px;
    }
    .coinInfoWrapper {
        flex-direction: column;
        margin-bottom: 50px;
    }
    .coinInfoStructure {
        min-width: unset;
    }
    .App {
        padding: 0 10px;
    }
    .header {
        margin: 0 -10px 50px -10px;
        padding: 10px;
        background-color: var(--black);
    }
    .navbar-margin {
        margin-right: 0;
        font-size: 24px;
    }
    .navbar {
        display: flex;
        width: 100%;
        height: 350px;
        position: absolute;
        top: -2000px;
        opacity: 0;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
        flex-direction: column;
        justify-content: space-around;
    }
    .navbar.active {
        background: var(--black);
        left: 0;
        opacity: 1;
        z-index: 1;
        top: 60px;
        align-content: center;
        padding-left: 0px;
    }
    .menu-icon {
        width: 45px;
        height: 45px;
    }
    .mobile-menu {
        display: block;
    }
    .uploadPlaceholder {
        width: 100%;
    }
    .uploadPlaceholderDrag {
        width: 100%;
    }
    .add2row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .add2row>.text {
        width: 100%;
    }
    .add2row>.text:first-child {
        margin-bottom: 20px;
    }
    .addcol>.text {
        margin-top: 20px;
    }
    .textSearch {
        width: 100%;
    }
    .hugeTitle {
        font-size: 40px;
    }
    .tableHeader {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
        margin-bottom: 20px;
    }
    .tableImgStructure {
        min-width: 180px;
    }
    .titleCarousel {
        flex-direction: column-reverse;
    }
    .watchDescription {
        font-size: 14px;
        min-height: 100px;
        max-width: 100%;
    }
    .coinInfoWrapperInfo {
        max-width: 100%;
        margin-top: 50px;
    }
    .coinInfoCoinBrainWrapper {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }
    .advStructure {
        flex-direction: column;
        gap: 30px;
        margin-top: 30px;
        align-items: center;
    }
    .buttonVote {
        padding: 12px 12px;
        text-decoration: none;
        /* border: none; */
        min-width: 110px;
        font-size: 10px;
    }
    .iconVote {
        width: 18px;
        height: 18px;
    }
    .tableName {
        font-size: 12px;
    }
    .tableSymb {
        font-size: 10px;
    }
    .tableImg {
        width: 35px;
        height: 35px;
    }
    .advMobile {
        padding: 0 10px;
    }
    .react-tabs__tab {
        padding: 10px 5px;
        text-decoration: none;
        /* border: none; */
        min-width: 110px;
        width: 150px;
        font-size: 14px;
        text-align: center;
        transition: .2s all linear;
    }
    .rmdp-day {
        height: 28px !important;
        width: 28px !important;
    }
    .rmdp-day span {
        padding: 0 !important;
    }
    .cartTitle {
        font-size: 26px;
    }
    .cartData {
        font-size: 14px;
    }
    .cartWrapper {
        padding: 30px 20px;
    }
    .carousel-root {
        margin-bottom: 20px;
    }
    .topCardUrl {
        padding: 20px 15px;
    }
    .name {
        font-size: 16px;
    }
    .sym {
        font-size: 16px;
    }
    .footer-1 {
        flex-direction: column;
    }
    .footer {
        height: 100%;
    }
    .footer-cop {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .errorWrap {
        flex-direction: column;
        text-align: center;
    }
    .portfolioVisibility {
        margin-right: unset;
    }

    .scannerForm {
        flex-direction: column;
    }
    .scannerFormSelect {
        max-width: 400px;
        width: 100%;
    }

    .scannerSafetyIndex {
        align-items: start;
    }

    .scannerError {
        flex-direction: column;
        padding: 0 10px;
        gap: 20px;
        margin-top: 30px;
        margin-bottom: 0px;
    }
}

@media (max-width: 1060px) {
    .logo {
        height: 30px;
        width: 230px;
    }
    .App {
        padding: 0 10px;
    }

    .buttonVote {
        padding: 12px 12px;
        text-decoration: none;
        /* border: none; */
        min-width: 110px;
        font-size: 10px;
    }

    .iconVote {
        width: 18px;
        height: 18px;
    }

    .header {
        margin: 0 -10px 50px -10px;
        padding: 10px;
        background-color: var(--black);
    }
    .navbar-margin {
        margin-right: 0;
        font-size: 24px;
    }
    .navbar {
        display: flex;
        width: 100%;
        height: 350px;
        position: absolute;
        top: -2000px;
        opacity: 0;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
        flex-direction: column;
        justify-content: space-around;
    }
    .navbar.active {
        background: var(--black);
        left: 0;
        opacity: 1;
        z-index: 1;
        top: 60px;
        align-content: center;
        padding-left: 0px;
    }
    .menu-icon {
        width: 45px;
        height: 45px;
    }
    .mobile-menu {
        display: block;
    }
}